import Articles from 'components/Articles';
import GenreSectionWrapper from './primitives/GenreSectionWrapper';
import Head from './Head';
import PageBody, { ViewName } from 'views/PageBody';
import PlayableArticle from 'shared/ui/components/PlayableArticle';
import Section from 'components/Section';
import usePageView from 'modules/Analytics/usePageView';
import useTranslate from 'contexts/TranslateContext/useTranslate';
import { getCurrentPath } from 'state/Routing/selectors';
import { getGenres } from 'state/Genres/selectors';
import { getGenresRecs } from 'state/Recs/selectors';
import { getSiteUrl } from 'state/Config/selectors';
import { slugify } from 'utils/string';
import { sortBy } from 'lodash-es';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

function GenrePage() {
  const translate = useTranslate();
  const genres = useSelector(getGenres);
  const pagePath = useSelector(getCurrentPath);
  const recs = useSelector(getGenresRecs);
  const siteUrl = useSelector(getSiteUrl);

  const social = useMemo(
    () => ({
      hideDescription: true,
      supportsConnect: true,
      url: `${siteUrl}${pagePath}`,
    }),
    [pagePath, siteUrl],
  );

  usePageView({ pageName: 'genre_directory' });

  return (
    <>
      <Head />
      <PageBody
        dataTest={ViewName.GenreDirectory}
        social={social}
        title={translate('Find Stations by Genre')}
      >
        {sortBy(Object.values(genres), ['name']).map(({ id, name }) => {
          const genreUrl = id
            ? `/genre/${slugify(name.toLowerCase())}-${id}/`
            : null;
          const genreRecs = recs[id];

          if (!genreRecs) return null;

          return (
            <GenreSectionWrapper key={id}>
              <Section
                dataTest="genre-directory-articles-section"
                header={name}
                key={id}
                url={genreUrl ?? undefined}
              >
                <Articles articleClass={PlayableArticle} data={genreRecs} />
              </Section>
            </GenreSectionWrapper>
          );
        })}
      </PageBody>
    </>
  );
}

export default GenrePage;
